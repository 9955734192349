import "./App.css"
import "reflect-metadata"
import { BirProvider } from "./Context/BirContextProvider"
import AppRouter from "./Routes/AppRouter"
import { GeneralEventDispatcherContextProvider } from "./eventDispatcher/event-dispatcher-context"
import { BrowserRouter as Router } from "react-router-dom"


function App() {
  return (
    <GeneralEventDispatcherContextProvider>
      <BirProvider>
        <Router>
          <AppRouter />
        </Router>
      </BirProvider>
    </GeneralEventDispatcherContextProvider>
  )
}

export default App
