import { SecondaryButton } from "bionline-components-library"
import { AnimatePresence } from "framer-motion"
import React, {
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from "react"
import { ContainerAnimated, StyledRow } from "../UIScreen.styles"

interface Props {
  setLoadingFragment?: React.Dispatch<SetStateAction<boolean | undefined>>
  initialAnimation?: boolean
  showComponent?: boolean
  children?: JSX.Element | JSX.Element[] | undefined
  width?: number
  showCancelButton?: boolean
  cancelFn?: ({ ...args }: any) => void
  paddingBtnCancel?: number
}

export const UISCreenContent: React.FC<Props> = ({
  setLoadingFragment,
  initialAnimation,
  showComponent,
  children,
  showCancelButton,
  paddingBtnCancel,
  cancelFn,
  width
}: Props) => {
  const variantScreen = useMemo(
    () => ({
      closed: {
        x: width ?? 675,
        y: 0
      },
      open: {
        x: 0,
        y: 0
      }
    }),
    [width]
  )

  const [showCancel, setShowCancel] = useState(false)

  const handleInitComplete = useCallback(() => {
    setShowCancel(showCancelButton as boolean)
  }, [showCancelButton])

  return (
    <AnimatePresence mode="wait" initial={initialAnimation}>
      {showComponent && (
        <ContainerAnimated
          variants={variantScreen}
          initial="closed"
          transition={{ duration: 0.8 }}
          animate="open"
          exit="closed"
          className="containerAnimated"
          onAnimationEnd={handleInitComplete}
        >
          {Array.isArray(children)
            ? children.map((ch) => {
                return React.cloneElement(ch, { setLoadingFragment })
              })
            : React.cloneElement(children as any, { setLoadingFragment })}

          {showCancel && (
            <StyledRow paddingBtn={paddingBtnCancel} className="btnCancelar">
              <SecondaryButton onClick={cancelFn}>Cancelar</SecondaryButton>
            </StyledRow>
          )}
        </ContainerAnimated>
      )}
    </AnimatePresence>
  )
}
