import { ApiLanguage, Helpers } from "bionline-library-utilities";
import { useBirState } from "../../Context/BirContextProvider";

import diccionario from "./Common.translations/SideBar";
import { UserLogin } from "../../eventDispatcher/eventos/types";

export function useCommonDictionary() {
  const { user } = useBirState();
  const lang = Helpers.getApiLanguage((user as UserLogin).idioma as ApiLanguage ?? 1)

  return diccionario[lang];
}
