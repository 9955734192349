import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import { useGeneralEventDispatcher } from "../eventDispatcher/event-dispatcher-context"
import {
  ShowHelp,
  expandSidebar,
  setUserLogin
} from "../eventDispatcher/eventos/core_events"
import { CORE_EVENTS, UserLogin } from "../eventDispatcher/eventos/types"

export type BirContextType = {
  user: UserLogin | undefined
  isSideBarExpanded: boolean | undefined
  showHelp: boolean | undefined
  setUser:React.Dispatch<React.SetStateAction<UserLogin | undefined>>
}

export interface BirContextProps {
  children: React.ReactNode
}

const BirContext = createContext<BirContextType | undefined>(undefined)

export function BirProvider({ children }: BirContextProps) {
  const generalEventDispatcher = useGeneralEventDispatcher()

  const [user, setUser] = useState<UserLogin | undefined>(() => {
    return (
      (window.sessionStorage.getItem("user") !== undefined &&
        JSON.parse(window.sessionStorage.getItem("user") as string)) ||
      undefined
    )
  })
  const [isSideBarExpanded, toggleSideBar] = useState<boolean | undefined>(
    false
  )

  const [showHelp, setShowHelp] = useState<boolean | undefined>(false)
  useEffect(() => {
    if (user) {
      let new_user = JSON.stringify(user)
      window.sessionStorage.setItem("user", new_user)
    } else {
      window.sessionStorage.removeItem("user")
    }
  }, [user])

  const callbackSetUser = useCallback(
    (evt: setUserLogin<typeof CORE_EVENTS.SET_USER>) => {
      setUser(evt.user)
    },
    []
  )

  const callbackExpandSidebar = useCallback(
    (evt: expandSidebar<typeof CORE_EVENTS.EXPAND_SIDEBAR>) => {
      toggleSideBar(evt.expand)
    },
    []
  )

  const callbackShowHelp = useCallback(
    (evt: ShowHelp<typeof CORE_EVENTS.SHOW_HELP>) => {
      setShowHelp(evt.open)
    },
    []
  )

  useEffect(() => {
    generalEventDispatcher.subscribe(
      CORE_EVENTS.EXPAND_SIDEBAR,
      callbackExpandSidebar
    )
    generalEventDispatcher.subscribe(CORE_EVENTS.SET_USER, callbackSetUser)
    generalEventDispatcher.subscribe(CORE_EVENTS.SHOW_HELP, callbackShowHelp)
    return function () {
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.EXPAND_SIDEBAR,
        callbackExpandSidebar
      )
      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.SET_USER,
        callbackSetUser
      )

      generalEventDispatcher.removeSubscriber(
        CORE_EVENTS.SHOW_HELP,
        callbackShowHelp
      )
    }
  }, [
    callbackExpandSidebar,
    callbackSetUser,
    callbackShowHelp,
    generalEventDispatcher
  ])

  let estado: BirContextType = {
    user,
    isSideBarExpanded,
    showHelp,
    setUser
  }

  return <BirContext.Provider value={estado}>{children}</BirContext.Provider>
}

export function useBirState() {
  const context = useContext(BirContext)
  if (context === undefined) {
    throw new Error("This state must be used within a Provider")
  }
  return context
}
