import { DashboardHome } from "bionline-modules"
import { useBirState } from "../../../Context/BirContextProvider"
import {
  AnimatedLayer,
  ParentVariant
} from "../../../core/components/AnimatedLayer/AnimatedLayer.styled"
import { useGeneralEventDispatcher } from "../../../eventDispatcher/event-dispatcher-context"

function Dashboard() {
  const { user } = useBirState()
  const generalEventDispatcher = useGeneralEventDispatcher()
  return (
    <AnimatedLayer
      variants={ParentVariant}
      initial="initial"
      animate="animated"
      exit="exit"
    >
      <DashboardHome
        user={user}
        generalEventDispatcher={generalEventDispatcher}
      />
    </AnimatedLayer>
  )
}

export default Dashboard
