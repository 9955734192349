import { EventDispatcher, EventDispatcherClass } from 'bionline-library-utilities';

import { createContext, useContext } from "react"
import { CoreEvents } from './eventos/core_events';





export const EventDispatcherSingleton =
  EventDispatcherClass.getInstance<CoreEvents>()

const EventDispatcherContext = createContext<EventDispatcher<CoreEvents>>(
  EventDispatcherSingleton
)

export function useGeneralEventDispatcher() {
  return useContext(EventDispatcherContext)
}

export function GeneralEventDispatcherContextProvider({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <EventDispatcherContext.Provider value={EventDispatcherSingleton}>
      {children}
    </EventDispatcherContext.Provider>
  )
}
