import React, { useMemo, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Sidebar as SidebarComponent } from "bionline-components-library"
import { useBirState } from "../../../Context/BirContextProvider"
import { useCommonDictionary } from "../../i18n/useCommonDictionary"
import {
  FiBell,
  FiDatabase,
  FiDownload,
  FiFile,
  FiHelpCircle,
  FiHome,
  FiLayers,
  FiLogIn,
  FiLogOut,
  FiSettings,
  FiShuffle,
  FiUser,
  FiUsers
} from "react-icons/fi"
import { useGeneralEventDispatcher } from "../../../eventDispatcher/event-dispatcher-context"
import { CORE_EVENTS } from "../../../eventDispatcher/eventos/types"
import { ApiLanguage, Helpers } from "bionline-library-utilities"
import { SIN_BIDATA } from "../../constants"
import { UserLogin } from "../../types/user-login"

const Sidebar = () => {
  const path = useLocation()
  const navigate = useNavigate()
  const generalEventDispatcher = useGeneralEventDispatcher()

  const { isSideBarExpanded, user, showHelp } = useBirState()
  const lang = Helpers.getApiLanguage((user?.idioma ?? 1) as ApiLanguage)

  const toggleSideBar = useCallback(() => {
    return generalEventDispatcher.dispatch(CORE_EVENTS.EXPAND_SIDEBAR, {
      type: CORE_EVENTS.EXPAND_SIDEBAR,
      expand: !isSideBarExpanded,
      timestamp: new Date(),
      lang
    })
  }, [generalEventDispatcher, lang, isSideBarExpanded])

  const {
    Usuarios,
    Perfil,
    CerrarSesion,
    TiposAccion,
    Acciones,
    CasosUso,
    Ficheros,
    Ajustes,
    Notificaciones
  } = useCommonDictionary()

  const handleLogOut = useCallback(() => {
    navigate("/auth/logout")
  }, [navigate])

  const handleShowHelp = useCallback(() => {
    return generalEventDispatcher.dispatch(CORE_EVENTS.SHOW_HELP, {
      type: CORE_EVENTS.SHOW_HELP,
      open: !showHelp as boolean,
      timestamp: new Date(),
      lang
    })
  }, [generalEventDispatcher, lang, showHelp])

  const handleNavigation = useCallback(
    (path: string) => {
      navigate(path)
    },
    [navigate]
  )

  const navItems = useMemo(() => {
    let nav = {
      main: [
        /*{
          title: "Dashboard",
          icon: FiHome,
          path: "/audiences/home",
          disabled:((user as UserLogin)?.inIngestaDatos === 1 && (user as UserLogin)?.inCompletadaIngesta === 0),
          action: () => {
            handleNavigation("/audiences/home")
          }
        },*/
        {
          title: Acciones,
          icon: FiUsers,
          path: "/audiences/accion-comercial/home",
          disabled:
            (user as UserLogin)?.inVisualizarIngestaDatos === 1 &&
            (user as UserLogin)?.inCompletadaIngesta === 0,
          action: () => {
            handleNavigation("/audiences/accion-comercial/home")
          }
        },
        {
          title: TiposAccion,
          icon: FiShuffle,
          disabled:
            (user as UserLogin)?.inVisualizarIngestaDatos === 1 &&
            (user as UserLogin)?.inCompletadaIngesta === 0,
          path: "/audiences/tipos-accion",
          action: () => {
            handleNavigation("/audiences/tipos-accion")
          }
        },
        {
          title: CasosUso,
          icon: FiLayers,
          disabled:
            (user as UserLogin)?.inVisualizarIngestaDatos === 1 &&
            (user as UserLogin)?.inCompletadaIngesta === 0,
          path: "/audiences/casos-uso/home",
          action: () => {
            handleNavigation("/audiences/casos-uso/home")
          }
        },
        {
          title: Ficheros,
          icon: FiFile,
          disabled: false,
          path: "/bihub/home",
          action: () => {
            handleNavigation("/bihub/home")
          }
        },
        {
          title: "BiData",
          icon: FiDatabase,
          disabled:
            (user as UserLogin)?.inVisualizarIngestaDatos === 1 &&
            (user as UserLogin)?.inCompletadaIngesta === 0,
          path: "/bidata/home",
          action: () => {
            handleNavigation("/bidata/home")
          }
        }
      ],
      footer: [
        /*
        {
          title: "Ayuda",
          icon: FiHelpCircle,
          disabled:((user as UserLogin)?.inIngestaDatos === 1 && (user as UserLogin)?.inCompletadaIngesta === 0),
          action: () => {
            handleShowHelp()
          },
          path: "/help"
        },*/
        {
          title: Ajustes,
          icon: FiSettings,

          path: "/auth",
          children: [
            {
              title: Notificaciones,
              icon: FiBell,
              disabled:
                (user as UserLogin)?.inVisualizarIngestaDatos === 1 &&
                (user as UserLogin)?.inCompletadaIngesta === 0,
              action: () => {
                handleNavigation("/events/home")
              },
              path: "/events/home"
            },
            {
              title: Perfil,
              icon: FiUser,

              action: () => {
                handleNavigation("/auth/profile")
              },
              path: "/auth/profile"
            }
          ]
        },
        {
          title: CerrarSesion,
          icon: FiLogOut,
          action: () => {
            handleLogOut()
          },
          logout: true
        }
      ]
    }

    if ((user as UserLogin)?.inVisualizarIngestaDatos === 1) {
      nav.main.push({
        title: "Ingesta datos",
        icon: FiDownload,
        path: "/bloques",
        disabled: false,
        action: () => {
          handleNavigation("/bloques")
        }
      })
    }
    if (user?.role === "Admin") {
      nav.footer[0].children?.push({
        title: Usuarios,
        icon: FiUsers,
        path: "/auth/users",
        action: () => {
          handleNavigation("/auth/users")
        }
      })
    }

    

    const customer = user?.idCustomer?.toUpperCase()
    if (customer && SIN_BIDATA[customer as keyof typeof SIN_BIDATA]) {
      nav.main.pop()
    }

    return nav
  }, [
    Acciones,
    TiposAccion,
    CasosUso,
    Ficheros,
    Ajustes,
    Notificaciones,
    Perfil,
    CerrarSesion,
    user,
    handleNavigation,
    handleLogOut,
    Usuarios
  ])

  return (
    <SidebarComponent
      expanded={isSideBarExpanded ?? false}
      setExpanded={
        toggleSideBar as React.Dispatch<React.SetStateAction<boolean>>
      }
      items={navItems.main}
      footerItems={navItems.footer}
      logo="/Logo.png"
      logoExpanded="/Logo-expanded.png"
      path={path.pathname}
    />
  )
}

export default React.memo(Sidebar)
