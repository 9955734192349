import { Languages } from "bionline-library-utilities"
import literales_en from "./en-US"
import literales_es from "./es-ES"

const { LANGUAGES } = Languages
const diccionario = {
  [LANGUAGES.ENGLISH]: literales_en,
  [LANGUAGES.SPANISH]: literales_es
}

export default diccionario
