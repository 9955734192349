import { EventDispatcher, Language } from "bionline-library-utilities"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { RightSideBar } from "bionline-components-library"
import {
  CoreEvents,
  ShowPanelCore
} from "../../../eventDispatcher/eventos/core_events"
import {
  CORE_EVENTS,
  ComponentPanel,
  SCREEN_OPTIONS
} from "../../../eventDispatcher/eventos/types"
import { useRightPanel } from "../../hooks/useRightPanel"
import { UISCreenContent } from "./UIScreenContent/index"

interface ScreenProps {
  children: React.ReactNode
  eventDispatcher: EventDispatcher<CoreEvents>
  lang: Language
}

export const UIScreen: React.FC<ScreenProps> = ({
  children,
  lang,
  eventDispatcher
}) => {
  const [component, setComponent] = useState<ComponentPanel>()

  const { panelRef, showPanel, setShowPanel } = useRightPanel({
    clickOutside: component ? component.clickOutside ?? true : true
  })
  const historialEventosRef =
    useRef<ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>[]>()
  const [historialEventos, setHistorialEventos] = useState<
    ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>[]
  >([])
  const [evento, setEvento] =
    useState<ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>>()

  const [showComponent, setShowComponent] = useState(false)
  const [revisarPanelComponent, setRevisarPanelComponent] =
    useState<boolean>(false)

  const [totalEventos, setTotalEventos] = useState<
    ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>[]
  >([])
  const handleCallbackPanelComponent = useCallback(
    (event: ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>) => {
      setTotalEventos((total) => [...total, event])
      if (event.open) {
        setHistorialEventos((historial) => {
          const historialMap = historial.map((h) => h.screen)
          let historialDef
          if (historialMap.includes(event.screen)) {
            historialDef = [
              ...historial.filter((t) => t.screen !== event.screen),
              event
            ]
          } else {
            historialDef = [...historial, event]
          }

          return historialDef
        })
      } else {
        setHistorialEventos((historial) => {
          return [...historial.filter((t) => t.screen !== event.screen)]
        })
      }
    },
    []
  )

  useEffect(() => {
    if (evento !== undefined) {
      handleCallbackPanelComponent(evento)
    }
  }, [evento, handleCallbackPanelComponent])

  useEffect(() => {
    if (historialEventosRef.current === historialEventos) return

    historialEventosRef.current = historialEventos
    /*
    if (
      accionComercialEventDispatcher &&
      historialEventos.length > 0 &&
      historialEventos[historialEventos.length - 1]?.screen ===
        SCREEN_OPTIONS.MODIFICAR_ACCION_COMERCIAL
    ) {
      accionComercialEventDispatcher.dispatch(
        ACCION_COMERCIAL_EVENTS.EVENT_RESET_FILTRO_ACCION_COMERCIAL_TMP,
        {
          type: ACCION_COMERCIAL_EVENTS.EVENT_RESET_FILTRO_ACCION_COMERCIAL_TMP,
          timestamp: new Date(),
          lang,
        }
      )
    }
    */

    setRevisarPanelComponent(true)
  }, [historialEventos, lang])

  const replaceScreenRef = useRef<boolean>(false)

  const handleCallbackSetEvent = useCallback(
    (event: ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE>) => {
      if (
        event.screen === SCREEN_OPTIONS.CREATE_CAMPANYA &&
        event.open &&
        replaceScreenRef.current
      ) {
        return setTimeout(() => {
          setEvento(event)
          replaceScreenRef.current = false
        }, 1200)
      }

      setEvento(event)
    },
    []
  )

  const [isClosedPanel, setIsClosedPanel] = useState(false)

  const closePanel = useCallback(() => {
    setIsClosedPanel(true)
  }, [])

  const callbackFnClosePanel = useCallback(() => {
    /*
    if (
      historialEventos[historialEventos.length - 1]?.evento ===
      SCREEN_OPTIONS.SELECCIONAR_CAMPANYA
    ) {
      eventDispatcher.dispatch("eventShowFilterCampanyas", {
        type: "eventShowFilterCampanyas"
      })
    }
    */
    /*
    if (
      accionComercialEventDispatcher &&
      historialEventosRef.current[historialEventosRef.current.length - 1]
        ?.screen === SCREEN_OPTIONS.CREATE_CAMPANYA
    ) {
      accionComercialEventDispatcher.dispatch(
        ACCION_COMERCIAL_EVENTS.EVENT_CHANGE_MODE_CAMPANYA,
        {
          type: ACCION_COMERCIAL_EVENTS.EVENT_CHANGE_MODE_CAMPANYA,
          lang,
          timestamp: new Date(),
        }
      )
    }
    */
    if (component?.callbackClose) {
      component?.callbackClose()
    }
    setHistorialEventos([])
    setEvento(undefined)
    setTotalEventos([])
    setRevisarPanelComponent(true)
  }, [component])

  useEffect(() => {
    if (isClosedPanel) {
      setIsClosedPanel(false)
      callbackFnClosePanel()
    }
  }, [callbackFnClosePanel, isClosedPanel])

  const goBack = useCallback(() => {
    if (!historialEventosRef.current) return
    const { screen, component } =
      historialEventosRef.current[historialEventosRef.current.length - 1]

    const newEvent: ShowPanelCore<typeof CORE_EVENTS.SHOW_PANEL_CORE> = {
      screen,
      component,
      open: false,
      type: CORE_EVENTS.SHOW_PANEL_CORE,
      timestamp: new Date(),
      lang
    }
    setEvento(newEvent)
  }, [lang])

  /*
  const handleCallbackResetHistorial = useCallback(
    (
      event: ResetHistorialEventos<
        typeof ACCION_COMERCIAL_EVENTS.RESET_HISTORIAL_EVENTOS
      >
    ) => {
      if (historialEventos?.length > 0) {
        replaceScreenRef.current = true
      }
      setHistorialEventos([])

      setShowPanel(false)
      setEvento(undefined)
      setTotalEventos([])
      setShowComponent(false)
      setComponent(undefined)
    },
    [setShowPanel, historialEventos]
  )
  */

  useEffect(() => {
    eventDispatcher.subscribe(
      CORE_EVENTS.SHOW_PANEL_CORE,
      handleCallbackSetEvent
    )
    /*
    accionComercialEventDispatcher &&
      accionComercialEventDispatcher.subscribe(
        ACCION_COMERCIAL_EVENTS.RESET_HISTORIAL_EVENTOS,
        handleCallbackResetHistorial
      )
      */

    return function cleanUp() {
      eventDispatcher.removeSubscriber(
        CORE_EVENTS.SHOW_PANEL_CORE,
        handleCallbackSetEvent
      )
      /*
      accionComercialEventDispatcher &&
        accionComercialEventDispatcher.removeSubscriber(
          ACCION_COMERCIAL_EVENTS.RESET_HISTORIAL_EVENTOS,
          handleCallbackResetHistorial
        )*/
    }
  }, [eventDispatcher, handleCallbackSetEvent])

  useEffect(() => {
    if (revisarPanelComponent) {
      setRevisarPanelComponent(false)

      if (evento === undefined) {
        setShowComponent(false)
        setComponent(undefined)
        return setShowPanel(false)
      }

      if (evento?.open) {
        let lastIndex = [...totalEventos]
          .reverse()
          .findIndex((t) => t.screen === evento.screen)
        let evtAnterior = [...totalEventos].reverse()[lastIndex]
        let nextEvnt
        if (totalEventos.length > 1) {
          nextEvnt = [...totalEventos].reverse()[lastIndex + 1]
        }

        if (nextEvnt && nextEvnt?.screen !== evtAnterior?.screen) {
          setShowComponent(false)
          setComponent(undefined)
          setTimeout(() => {
            setComponent(evento.component)
            setShowComponent(true)
          }, 1000)
        } else {
          setComponent(evento.component)
          setShowComponent(true)
          setShowPanel(true)
        }
      } else {
        if (historialEventos.length > 0) {
          let newEvento = historialEventos[historialEventos.length - 1]

          eventDispatcher.dispatch(
            CORE_EVENTS.REQUEST_COMPONENT_FROM_SCREEN_OPTION,
            {
              type: CORE_EVENTS.REQUEST_COMPONENT_FROM_SCREEN_OPTION,
              option: newEvento?.screen,
              timestamp: new Date(),
              lang
            }
          )
        } else {
          setShowPanel(false)
          setShowComponent(false)
          setComponent(undefined)
          setTotalEventos([])
          setEvento(undefined)
        }
      }
    }
  }, [
    historialEventos,
    evento,
    revisarPanelComponent,
    setShowPanel,
    eventDispatcher,
    component,
    totalEventos,
    lang
  ])

  const estilo = useMemo(
    () => ({ padding: "0px", marginCrossIcon: "10px 0 0 0" }),
    []
  )

  const showCancelButton = useMemo(() => {
    return historialEventos?.length > 1 && !component?.hideCancelButton
  }, [component?.hideCancelButton, historialEventos?.length])

  return (
    <>
      <RightSideBar
        layoutRef={panelRef as React.MutableRefObject<HTMLDivElement>}
        width={component?.width ?? 675}
        show={showPanel}
        titulo={component?.title}
        setClose={closePanel}
        callbackClose={component?.callbackClose}
        style={{
          ...estilo,
          ...component?.estilo
        }}
      >
        <UISCreenContent
          initialAnimation={historialEventos?.length !== 1}
          showCancelButton={showCancelButton}
          showComponent={showComponent}
          width={component?.width ?? 675}
          paddingBtnCancel={component?.paddingBtnCancel ?? 30}
          cancelFn={component?.cancelFn ?? goBack}
        >
          {component?.component ?? <></>}
        </UISCreenContent>
      </RightSideBar>

      {children}
    </>
  )
}
