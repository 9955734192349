import { useCallback, useEffect, useRef, useState } from "react"

interface useRightPanelProps {
  clickOutside?: boolean
}

export function useRightPanel({ clickOutside=true }: useRightPanelProps) {
  const panelRef = useRef<HTMLDivElement>()

  const [showPanel, setShowPanel] = useState(false)

  const eventCallback = useCallback(
    (e: any) => {
      if (!clickOutside) return
      if (
        (e?.target?.className &&
          e?.target?.className?.toString().indexOf("notification") > -1) ||
        e?.target?.parentNode?.className?.toString().indexOf("notification") >
          -1 ||
        e?.target?.childNodes?.className?.toString().indexOf("notification") >
          -1
      )
        return e.preventDefault()
      if (!panelRef.current?.contains(e.target)) {
        setShowPanel(false)
      }
    },
    [clickOutside]
  )

  useEffect(() => {
    window.addEventListener("click", eventCallback)
    return () => {
      window.removeEventListener("click", eventCallback)
    }
  }, [eventCallback, showPanel])

  return {
    panelRef,
    showPanel,
    setShowPanel,
  }
}
