import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useGeneralEventDispatcher } from "../../../eventDispatcher/event-dispatcher-context"
import { CORE_EVENTS } from "../../../eventDispatcher/eventos/types"
import { Languages } from "bionline-library-utilities"

const { LANGUAGES } = Languages
const Logout = () => {
  const navigate = useNavigate()

  const generalEventDispatcher = useGeneralEventDispatcher()

  useEffect(() => {
    generalEventDispatcher.dispatch(CORE_EVENTS.SET_USER, {
      type: CORE_EVENTS.SET_USER,
      user: undefined,
      timestamp: new Date(),
      lang: LANGUAGES.SPANISH
    })

    window.sessionStorage.clear()
    navigate("/auth/signin")
  }, [generalEventDispatcher, navigate])

  return <>Saliendo...será rederigido en unos segundos</>
}

export default Logout
