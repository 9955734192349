import { Row } from "bionline-components-library"
import { ReactComponent as ReactLogo } from "./../../assets/svg/not_found.svg"

import { FiHome } from "react-icons/fi"
import { NavigateFunction } from "react-router-dom"
import { LinkLabel, NotFoundContainer } from "./notFound.styles"

interface NotFoundProps {
  navigate: NavigateFunction
}

export const NotFound = ({ navigate }: NotFoundProps) => {
  const handleGoBack = () => {
    navigate(-2)
  }
  return (
    <NotFoundContainer>
      <ReactLogo />
      <Row margin="70px 0 0 0" justifyContent="center">
        <LinkLabel className="link" onClick={() => handleGoBack()}>
          <FiHome /> Volver
        </LinkLabel>
      </Row>
    </NotFoundContainer>
  )
}
