import { LinkButton } from "bionline-components-library"
import styled from "styled-components"

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  min-height: 100%;
`

export const LinkLabel = styled(LinkButton)`
  &.link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;

    padding: 10px 0;
    min-width: 150px;
    svg {
      margin: 0 5px 0 0;
    }
  }

  &.link:hover {
    background: transparent;
    color: #8379c3;
    p {
      color: inherit;
    }
  }
`
