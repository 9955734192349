const literales_es = {
  Dashboard: "Dashboard",
  Acciones: "Acciones comerciales",
  CasosUso: "Casos de uso",
  Ficheros: "Ficheros",
  Ajustes: "Ajustes",
  Exito:"Éxito",
  Notificaciones: "Notificaciones",
  Perfil: "Perfil",
  Usuarios: "Usuarios",
  CerrarSesion: "Cerrar sesión",
  LtFiltros: "Filtros",
  Aceptar: "Aceptar",
  TiposAccion:"Tipos acción",
};

export default literales_es;
