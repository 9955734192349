import { Row } from "bionline-components-library"
import { motion } from "framer-motion"
import styled from "styled-components"

export const ContainerAnimated = styled(motion.div)`
  width: auto;
  position: relative;
  z-index: 1000;
  
`

interface StyledRowProps {
  paddingBtn?: number
}

export const StyledRow = styled(Row)<StyledRowProps>`
  &.btnCancelar {
    display: flex;
    flex: 1;
    margin: 20px 0;
    ${(props) =>
      props.paddingBtn && `width: calc(100% - ${2 * props.paddingBtn}px);`}
    padding-left:${(props) => `${props.paddingBtn}px`};
    button {
      width: 100%;
    }
  }
`
