import { motion } from "framer-motion"
import styled from "styled-components"

export const AnimatedLayer = styled(motion.div)`
  padding: 0;
  margin: 0;
`

export const ParentVariant = {
  initial: {
    opacity: 0
  },
  animated: {
    opacity: 1,
    transition: { duration: 0.7 }
  },
  exit: { opacity: 0, transition: { duration: 0.7 } }
}
