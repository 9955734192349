import { Container } from "bionline-components-library"
import SideBar from "../Sidebar"
import { motion, AnimatePresence } from "framer-motion"

import "./Layout.css"

interface ILayout {
  children: JSX.Element
  isLoggedIn: boolean
}

const Layout = ({ children, isLoggedIn }: ILayout) => {
  return (
    <>
      <div className={isLoggedIn ? "container-shell" : ""}>
        {isLoggedIn && <SideBar />}
        <AnimatePresence mode="wait">
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 20 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.15 }}
            className={isLoggedIn ? "container-shell-children" : ""}
          >
            {isLoggedIn ? (
              <Container className="container-children">{children}</Container>
            ) : (
              children
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  )
}

export default Layout
