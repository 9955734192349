import React from "react"
import { IRutasPrivadas } from "../core/types/common-types"
import Dashboard from "../Pages/acciones-comerciales/Dashboard"


const Perfil = React.lazy(() => import("../Pages/auth/perfil"))
const Usuarios = React.lazy(() => import("../Pages/auth/usuarios"))
const BiData = React.lazy(() => import("../Pages/bidata"))
const BiHub = React.lazy(() => import("../Pages/bihub"))
const Eventos = React.lazy(() => import("../Pages/eventos"))
const AltaAccionComercial = React.lazy(
  () => import("../Pages/acciones-comerciales/AltaAccionComercial")
)
const GetAccionComercial = React.lazy(
  () => import("../Pages/acciones-comerciales/GetAccionComercial")
)
const ListadoAcciones = React.lazy(
  () => import("../Pages/acciones-comerciales/ListadoAccionesComerciales")
)
const CasosUso = React.lazy(() => import("../Pages/casos-uso/GetCasosUso"))
const CasosUsoNestedRoutes = React.lazy(
  () => import("../Pages/casos-uso/NestedRoutes")
)
const TiposAccion = React.lazy(
  () => import("../Pages/tiposAccion/GetTiposAccion")
)
const TiposAccionCrear = React.lazy(
  () => import("../Pages/tiposAccion/CreateTipoAccion")
)

const IngestaModule = React.lazy(
  () => import("../Pages/ingesta")
)

export const RutasPrivadas: IRutasPrivadas[] = [
  {
    id: 0,
    path: "/auth/users",
    component: Usuarios,
    titulo: "Usuarios"
  },
  {
    id: 2,
    path: "/auth/profile",
    component: Perfil,
    titulo: "Perfil"
  },
  {
    id: 10,
    path: "/bihub/home",
    component: BiHub,
    titulo: "Bihub"
  },
  {
    id: 20,
    path: "/events/home",
    component: Eventos,
    titulo: "Eventos"
  },
  {
    id: 30,
    path: "/audiences/accion-comercial/home",
    component: ListadoAcciones,
    titulo: "Acciones comerciales"
  },
  {
    id: 31,
    path: "/audiences/accion-comercial/:idAccionComercial",
    component: GetAccionComercial,
    titulo: "Acción Comercial"
  },
  {
    id: 32,
    path: "/audiences/accion-comercial/alta",
    component: AltaAccionComercial,
    titulo: "Alta Acción Comercial"
  },

  {
    id: 33,
    path: "/audiences/tipos-accion",
    component: TiposAccion,
    titulo: "Tipos de acción"
  },
  {
    id: 34,
    path: "/audiences/tipos-accion/crear",
    component: TiposAccionCrear,
    titulo: "Alta tipo acción"
  },
  {
    id: 35,
    path: "/audiences/casos-uso/home",
    component: CasosUso,
    titulo: "Casos de uso"
  },
  {
    id: 36,
    path: "/audiences/casos-uso/alta/:step",
    component: CasosUsoNestedRoutes,
    titulo: "Alta caso uso"
  },
  {
    id: 37,
    path: "/audiences/casos-uso/:idCasoUso/alta/:step",
    component: CasosUsoNestedRoutes,
    titulo: "Alta caso uso - Edición caso uso"
  },
  {
    id: 40,
    path: "/bidata/home",
    component: BiData,
    titulo: "BiData"
  },
  {
    id: 50,
    path: "/audiences/home",
    component: Dashboard,
    titulo: "Dashboard"
  }
  ,
  {
    id: 60,
    path: "/bloques",
    component: IngestaModule,
    titulo: "Ingesta de datos"
  }
]
